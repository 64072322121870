import { connect } from 'react-redux'
import styled from 'styled-components'
import { device } from '../helpers/deviceSize'
import { aboutSelectors } from '../store/about'
import { uiSelectors } from '../store/ui'
import GoBackButton from './GoBackButton'
import Link from './StyledComponents/Link'

// const openClipPath = `50% 0%, 100% 50%, 50% 100%, 0% 50%`
// polygon(50vw -10vh, 110vw 50vh, 50vw 110vh, -10vw 50vh)

// 1280 X 800
// polygon(50vw -30vh, 100vw 50vh, 50vw 130vh, 0vw 50vh)
//  1080
// NULL
// MOBILE
// polygon(50vw -40vh,130vw 50vh,50vw 140vh,-30vw 50vh)

const laptopClosedClipPath = `95% 90%, 97% 95%, 95% 100%, 90% 95%`
const mobileClosedClipPath = `110% 110%, 110% 110%, 110% 110%, 110% 110%`
const mobileOpenClip = `50vw -40vh,130vw 50vh,50vw 140vh,-30vw 50vh`
const laptopOpenClipt = `50vw -30vh, 100vw 50vh, 50vw 130vh, 0vw 50vh`

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  @media ${device.mobileS} {
    clip-path: polygon(${p => (p.isOpen ? mobileOpenClip : mobileClosedClipPath)});
  }
  @media ${device.laptop} {
    clip-path: polygon(${p => (p.isOpen ? laptopOpenClipt : laptopClosedClipPath)});
  }

  z-index: ${p => (p.isOpen ? 1 : 0)};
  background-color: ${p => (p.isOpen ? 'rgba(0,0,0,0.8)' : 'rgba(255, 255, 255, 0)')};
  transition: all 0.3s ease-in-out;
  color: white;
  overflow-y: ${p => (p.isOpen ? 'scroll' : 'hidden')};
`
const Container = styled.div`
  max-width: 550px;
  margin: 0 auto;
  /* display: flex; */
  min-height: 100vh;
  padding: 0 0 50vh 0;
`
const Content = styled.div`
  padding: 0 1rem;
`

const Title = styled.h2``
const IntroText = styled.p`
  text-align: left;
`
const IntroImage = styled.img`
  max-width: 100%;
  height: auto;
  width: 100%;
`
const SectionWrapper = styled.div``

const SectionTitle = styled.h3`
  color: rgb(222 255 0 / 50%);
`

const SectionText = styled.p`
  text-align: center;
`
const Footer = styled.div``

const ThanksText = styled.p``

const ThanksImage = styled.img`
  max-width: 100%;
  height: auto;
  width: 8rem;
`

const GoBackButtonWrapper = styled.div`
  @media ${device.mobileS} {
    text-align: center;
  }
  @media ${device.laptop} {
    text-align: left;
  }
`

function About({ isAboutOpen, about }) {
  return (
    <Wrapper isOpen={isAboutOpen}>
      <Container>
        <GoBackButtonWrapper>
          <GoBackButton />
        </GoBackButtonWrapper>
        <Content>
          {/* <Title>{about.title}</Title> */}
          <IntroText>{about.introText}</IntroText>
          <IntroImage src={about.introImage} alt="Natali blugerman" />
          {about.sections.map(section => {
            return (
              <SectionWrapper key={section.title}>
                <SectionTitle>{section.title}</SectionTitle>
                {section.text.map((text, index) => (
                  <SectionText key={section.title + index}>{text}</SectionText>
                ))}
              </SectionWrapper>
            )
          })}
          <Footer>
            <Link href={`mailto:${about.footer.mail}`}>{about.footer.mail}</Link>
            <ThanksText>{about.footer.thanks}</ThanksText>
            {about.footer.thanksImages.map(image => (
              <ThanksImage key={image} src={image} />
            ))}
          </Footer>
        </Content>
      </Container>
    </Wrapper>
  )
}

const mapState = state => ({
  isAboutOpen: uiSelectors.isAboutOpen(state),
  about: aboutSelectors.getAbout(state)
})

export default connect(mapState)(About)
