import _fp from 'lodash/fp'

const initialState = {
  introDotOpen: false,
  activeWorkIndex: null,
  activeWorkImageIndex: null,
  isAboutOpen: false
}

export const uiReducer = (state = initialState, action = {}) => {
  if (action.type === 'EXPAND_INTRO_DOT') {
    return {
      ...state,
      introDotOpen: true
    }
  }

  if (action.type === 'SELECT_WORK_INDEX') {
    return {
      ...state,
      activeWorkIndex: action.workIndex,
      isAboutOpen: false
    }
  }

  if (action.type === 'SET_ACTIVE_WORK_IMAGE_INDEX') {
    return {
      ...state,
      activeWorkImageIndex: action.imageIndex
    }
  }
  if (action.type === 'OPEN_ABOUT') {
    return {
      ...state,
      isAboutOpen: true,
      activeWorkIndex: null
    }
  }

  return state
}

const getUi = _fp.get('ui')
const introDotOpen = _fp.pipe(getUi, _fp.get('introDotOpen'))
const getActiveWorkIndex = _fp.pipe(getUi, _fp.get('activeWorkIndex'))
const getActiveWorkImageIndex = _fp.pipe(getUi, _fp.get('activeWorkImageIndex'))
const isAboutOpen = _fp.pipe(getUi, _fp.get('isAboutOpen'))

export const uiSelectors = {
  getUi,
  introDotOpen,
  getActiveWorkIndex,
  getActiveWorkImageIndex,
  isAboutOpen
}

// const getSelectedWork = state =>

const setActiveWorkImageIndex = imageIndex => ({ type: 'SET_ACTIVE_WORK_IMAGE_INDEX', imageIndex })

const nextSelectedWorkImage = () => (dispatch, getState) => {
  const state = getState()
  const workIndex = getActiveWorkIndex(state)
  const selectedWork = _fp.get(workIndex)(_fp.get('works')(state))
  const activeImageIndex = getActiveWorkImageIndex(state)

  const nextIndex = activeImageIndex + 1 >= selectedWork.images.amount ? 0 : activeImageIndex + 1

  dispatch(setActiveWorkImageIndex(nextIndex))
}

const prevSelectedWorkImage = () => (dispatch, getState) => {
  const state = getState()
  const workIndex = getActiveWorkIndex(state)
  const selectedWork = _fp.get(workIndex)(_fp.get('works')(state))
  const activeImageIndex = getActiveWorkImageIndex(state)

  const prevImage = activeImageIndex <= 0 ? selectedWork.images.amount - 1 : activeImageIndex - 1

  dispatch(setActiveWorkImageIndex(prevImage))
}

const openAbout = () => ({ type: 'OPEN_ABOUT' })

export const uiActions = {
  selectWorkIndex: workIndex => ({ type: 'SELECT_WORK_INDEX', workIndex }),
  nextSelectedWorkImage,
  prevSelectedWorkImage,
  setActiveWorkImageIndex,
  openAbout
}
