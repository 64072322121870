import { useState } from 'react'
import GlobalStyle from './GlobalStyle'

import Video from './components/Video'
import Balls from './components/Balls'
import styled, { keyframes } from 'styled-components'
import Mask from './components/Mask'
import Work from './components/Work'
import About from './components/About'
import { connect } from 'react-redux'
import { uiActions, uiSelectors } from './store/ui'
import { isFinite } from 'lodash'
import RainbowText from './components/StyledComponents/RainbowText'
import { device } from './helpers/deviceSize'

const Title = styled.h1`
  font-weight: 400;
  color: white;
  text-transform: uppercase;

  span {
    display: block;
  }

  @media ${device.mobileS} {
    margin: 1rem;
  }
  @media ${device.laptop} {
    margin: 1.5rem;
  }
`

const Backdrop = styled.div`
  height: 100vh;
  width: 100vw;
  z-index: 1;
  position: relative;
  opacity: 0.7;
  background-color: rgba(0, 0, 0, 0.3);
`

const Header = styled.header`
  position: absolute;
  z-index: 10;
  color: #2e2e2e;
  left: 0;
  right: 0;
  top: 0;
`

function App({ activeWorkIndex, closeWork, isAboutOpen }) {
  return (
    <Mask>
      <GlobalStyle />
      <Video />
      <Backdrop onClick={closeWork} />
      {!isFinite(activeWorkIndex) && !isAboutOpen ? (
        <Header>
          <Title>
            <RainbowText>I will use any media to discover it</RainbowText>
          </Title>
        </Header>
      ) : null}
      <Balls show={!isFinite(activeWorkIndex) && !isAboutOpen} />
      <Work />
      <About />
    </Mask>
  )
}

const mapState = state => ({
  activeWorkIndex: uiSelectors.getActiveWorkIndex(state),
  isAboutOpen: uiSelectors.isAboutOpen(state)
})

const mapDispatch = {
  closeWork: () => uiActions.selectWorkIndex(null)
}

export default connect(mapState, mapDispatch)(App)
