import styled, { keyframes } from 'styled-components'

const rainbow_animation = keyframes`
  0%,100% {
        background-position: 0 0;
  }

  50% {
      background-position: 100% 0;
  }
`

const RainbowText = styled.span`
  color: white;
  text-align: center;
  margin: 0;
  transition: color 2s ease-in-out;
  text-transform: uppercase;
  background: linear-gradient(
    to right,
    #fff,
    rgb(222 255 0 / 50%),
    rgb(222 255 0 / 10%),
    rgb(222 255 0 / 800%),
    #fff
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  background-size: 400% 100%;
  animation: ${rainbow_animation} 10s ease-in-out infinite;
`

export default RainbowText
