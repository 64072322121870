import { combineReducers } from 'redux'
import { worksReducer } from './works'
import { uiReducer } from './ui'
import { aboutReducer } from './about'

export default combineReducers({
  works: worksReducer,
  ui: uiReducer,
  about: aboutReducer
})
