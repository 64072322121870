import styled from 'styled-components'

const Link = styled.a`
  color: white;
  display: inline-block;
  font-size: 1.2rem;
  margin-bottom: 1.2rem;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  transition: color 3s ease-in-out;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 100%;
    bottom: -0.25rem;
    border-bottom: 0.1rem solid #deff00;
    transition: right 1s ease-in-out;
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -0.25rem;
    border-bottom: 0.1rem solid white;
  }
  &:hover {
    color: #deff00;
    &::after {
      right: 0;
    }
  }
`
export default Link
