import { connect } from 'react-redux'
import { createGlobalStyle } from 'styled-components'
import { device } from './helpers/deviceSize'

const Styles = createGlobalStyle`
    body {
      margin: 0;
      border: ${props => props.pad}px solid red;
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-family: 'Montserrat', sans-serif;
      text-align: center;

      background-color: black;
    }

    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
    }
    html {
      box-sizing: border-box;
    }
    *, *:before, *:after {
      box-sizing: inherit;
    }

    h1 {
      @media ${device.mobileS} {
        font-size: 1.2rem;
      }
      @media ${device.laptop} {
        font-size: 1.7rem;
      }
    }

    h3 {
      @media ${device.mobileS} {
        font-size: 1.2rem;
      }
      @media ${device.laptop} {
        font-size: 1.5rem;
      }
    }
    
    p {
      @media ${device.mobileS} {
        font-size: 1rem;
        line-height: 1.2;

      }
      @media ${device.laptop} {
        font-size: 1.2rem;
        line-height: 1.3;
      }
    }
    ::-webkit-scrollbar {
      width: 8px;
      background-color: #333;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #111;
      border: 2px solid transparent;
      border-radius: 0px;
      background-clip: padding-box;
    }
    
  `

export default connect(null)(Styles)

// 230
// 150
// 170
// 200
