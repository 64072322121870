import _fp from 'lodash/fp'

const defaultWorks = [
  {
    shortName: 'LO#1',
    title: `LIGHT OBSERVATORY #1`,
    images: {
      root: '/images/lo1',
      amount: 2
    },
    text: [
      'Light Observatory #1 finds its shape in an external representation of how vision works: sunrays enter our retina from a 360° conical direction and as data sets inside our eyes upside-down, our brain flips it again to see things in their actual position. In LO#1 people will gradually go into a 10 meters cone where they will find themselves in a meta-process of the same shape that reproduces itself from inside out and outside in. Work in progress.',
      'Drawings by architect Santiago Brignardelli',
      'Designed in: 2015'
    ]
  },
  {
    shortName: 'LO#2',
    title: `LIGHT OBSERVATORY #2`,
    images: {
      root: '/images/lo2',
      amount: 8
    },
    text: [
      `In Light Observatory #2 both body and mind are challenged, as one needs to use the highest and the lowest part of the body to navigate the installation, and have a very good sense of proprioception as the space becomes darker and darker. When the person seats in the chair in the last compartment, will find a very shiny “star” of sunlight entering directly to its eyes from in between the triangles and, while focusing in this tiny light within this overwhelming darkness, will start “seeing” other things. The mind starts creating com- pletely personal images that become increasingly intense throughout time.`,
      `Shown in: 2016 & 2020`
    ]
  },
  {
    shortName: 'LO#3',
    title: `LIGHT OBSERVATORY #3`,
    images: {
      root: '/images/lo3',
      amount: 2
    },
    text: [
      'Light Observatory #3 is a collective experience. A thin line of sunlight enters a black dark space where people are scatterly seated. Refraction, Vibrations. The minds inside start playing and creating images that might not physically be there.',
      'Shown in: 2017'
    ]
  },
  {
    shortName: 'LO#4',
    title: `LIGHT OBSERVATORY #4`,
    images: {
      root: '/images/lo4',
      amount: 1
    },
    text: [
      `In Light Observatory #4, four participants sit around a table, and each is visually immersed in a different space, which they can manipulate. A question triggers a discussion: “How to create balance between human and non-human for a space to be harmonious?”.`,
      'Shown in: 2019'
    ]
  },
  {
    shortName: 'LO#5',
    title: `LIGHT OBSERVATORY #5`,
    images: {
      root: '/images/lo5',
      amount: 0
    },
    text: [`In progress`]
  },
  {
    shortName: 'LO#6',
    title: `LIGHT OBSERVATORY #6`,
    images: {
      root: '/images/lo6',
      amount: 0
    },
    text: [`In progress`]
  },
  {
    shortName: 'LO#7',
    title: `LIGHT OBSERVATORY #7`,
    images: {
      root: '/images/lo7',
      amount: 0
    },
    videos: {
      root: '/videos/lo7',
      amount: 1
    },
    text: []
  },
  {
    shortName: 'TSN',
    title: `THE SENSORIAL NEIGHBOURHOOD`,
    images: {
      root: '/images/tsn',
      amount: 15
    },
    text: [
      `The Sensorial Neighbourhood, is a virtual urban design and artistic body that manifests itself in the urban scape. Its inhabitants create a fingerprint for interventions they wished occurred in their surroundings, and the neighbourhood’s designers form a creative group to materialize this wish.`,
      `The sensorial neighbourhood cherishes and promotes collaboration, creativity, and intuition as means to explore and re-invent the cities we live at.`,
      `Shown in: 2020`
    ],
    links: [
      {
        href: 'https://thesensorialneighbourhood.org/',
        label: 'thesensorialneighbourhood.org'
      },
      {
        href: 'https://www.instagram.com/thesensorialneighbourhood/',
        label: 'ig: thesensorialneighbourhood'
      }
    ]
  },
  {
    shortName: 'ODL',
    title: `OLFACTORY DREAMING LECTURES`,
    images: {
      root: '/images/odl',
      amount: 4
    },
    text: [
      `In the Olfactory dreaming performative lectures I guide people to smell with their minds instead of with their noses. In 2017 I started a research with visually impaired people to get more insight on how they “see”. While talking to some of them, they told me many of them dream in smells, and other senses, instead of in images. This fascinated me and I diverted my research into olfactory dreaming.`,
      `Shown in: 2018`
    ]
  },
  {
    shortName: 'PYA',
    title: `PLAY YOUR AiR`,
    images: {
      root: '/images/pya',
      amount: 5
    },
    text: [
      `Play Your AiR is a design i made for the Goethe Institute AiR. In this proposal, I invite the residents to play The Incomplete Game. They play alone, but together.`,
      `Each new resident continues the game the last resident left. In this way their communication is through the moldable space, which acts, in a way, as a timeline. The residence and the experimentation are the game itself. The space is the game and how each person plays it, brings an outcome, which is a source of knowledge and discovery.`,
      `Shown in: 2019`
    ]
  },
  {
    shortName: 'IS',
    title: `INVERTED SOUNDWALK`,
    images: {
      root: '/images/is',
      amount: 4
    },
    text: [
      `Inverted soundwalk consists of wearable microphones which are placed on each ancle, both connected directly to headphones. The person using the device goes for a walk, listening to the sounds that originate at ground level. This work was inspired by the fact that our body actually listens in all its height, but because our ears are so high up, we miss out a lot of information. What this device tries to do is to enhance the awareness of being vertical, creating a closed circuit between lower and upper sounds.`,
      `Shown in: 2015, 2017 & 2018`
    ]
  }
]

export function worksReducer(state = defaultWorks) {
  return state
}

const getWorks = _fp.get('works')

export const worksSelectors = {
  getWorks
}
