import { useEffect, useState, useCallback } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import styled, { keyframes } from 'styled-components'
import { uiActions, uiSelectors } from '../store/ui'
import { worksSelectors } from '../store/works'
import { device } from '../helpers/deviceSize'
import FadeGallery from './Gallery/FadeGallery'
import Link from './StyledComponents/Link'
import RainbowText from './StyledComponents/RainbowText'
import GoBackButton from './GoBackButton'
import ReactPlayer from 'react-player'

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.8);
  /* color: rgba(255, 255, 255, 0.8); */
  color: #fafafa;
  clip-path: circle(55% at center);
  overflow-y: scroll;

  /* border-top-right-radius: 10rem; */
  /* display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center; */
  /* clip-path: polygon(85vw 0, 100% 15vw, 100% 100%, 0 100%, 0 0); */
  /* clip-path: polygon(10vw 0, 100% 0, 100% 100%, 0 100%, 0% 10vw); */
  @media ${device.mobileS} {
    clip-path: circle(100% at center);
  }

  @media ${device.laptop} {
    clip-path: circle(60% at center);
  }
`
const Container = styled.div`
  max-width: 550px;
  margin: 0 auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: ${p => (p.centered ? 'center' : 'flex-start')};
`

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-left: -0.8rem;
  @media ${device.mobileS} {
  }
  @media ${device.laptop} {
    margin-left: -3.8rem;
  }
`
const Title = styled.h3`
  font-weight: 400;
  text-align: left;
  margin: 0;
  padding: 1.5rem 0;
`

const Content = styled.div`
  padding: 0 1rem;
`

const TextWrapper = styled.div`
  text-align: left;
`

const Text = styled.p``

const VideoThumbnail = styled.img`
  width: 100%;
  height: auto;
  margin: 0;
`

function Work({
  activeWork,
  closeWork,
  activeWorkIndex,
  nextSelectedWorkImage,
  prevSelectedWorkImage,
  setActiveWorkImageIndex,
  activeImageIndex
}) {
  const [showBackButtonAnimation, setShowBackButtonAnimation] = useState(false)
  const onMouseEnterHeader = useCallback(() => setShowBackButtonAnimation(true))
  const onMouseLeaveHeader = useCallback(() => setShowBackButtonAnimation(false))

  useEffect(() => {
    if (activeWork) {
      setActiveWorkImageIndex(0)
      setShowBackButtonAnimation(false)
    }
  }, [activeWork])

  if (!activeWork) return null

  const imageRootSrc = `${activeWork.images.root}/`
  const images = _.times(activeWork.images.amount, imageCount => ({
    src: `${imageRootSrc}/${imageCount + 1}.jpg`,
    alt: `${activeWork.title} ${imageCount + 1}`
  }))

  const videosRootSrc = `${activeWork?.videos?.root}`
  const videos = _.times(activeWork?.videos?.amount, imageCount => ({
    url: `${videosRootSrc}/${imageCount + 1}.mp4`,
    thumbnail: `${videosRootSrc}/${imageCount + 1}.png`
  }))

  const video = _.get(videos, [0])

  return (
    <>
      <Wrapper id="Works-Wrapper">
        <Container centered={activeWork.images.amount === 0 && !video}>
          <Content id="Works-Content">
            <Header onMouseEnter={onMouseEnterHeader} onMouseLeave={onMouseLeaveHeader}>
              <GoBackButton
                onClick={closeWork}
                showAnimation={showBackButtonAnimation}
                sr-only="Go back"
              />
              <Title>{activeWork.title}</Title>
            </Header>

            <TextWrapper>
              {activeWork.text.map((paragraph, i) => (
                <Text key={i}>
                  {paragraph === 'In progress' ? <RainbowText>{paragraph}</RainbowText> : paragraph}
                </Text>
              ))}
            </TextWrapper>
            {video ? (
              <ReactPlayer
                controls
                url={video.url}
                light={<VideoThumbnail src={video.thumbnail} />}
                width="100%"
              />
            ) : (
              <FadeGallery
                images={images}
                activeWorkIndex={activeWorkIndex}
                activeImageIndex={activeImageIndex}
                prevImage={prevSelectedWorkImage}
                nextImage={nextSelectedWorkImage}
              />
            )}

            <TextWrapper>
              {activeWork?.links?.map(({ href, label }) => (
                <Link href={href} key={label} target="_blank">
                  {label}
                </Link>
              ))}
            </TextWrapper>
          </Content>
        </Container>
      </Wrapper>
    </>
  )
}

const mapState = state => ({
  activeWork: _.get(worksSelectors.getWorks(state), uiSelectors.getActiveWorkIndex(state)),
  activeWorkIndex: uiSelectors.getActiveWorkIndex(state),
  activeImageIndex: uiSelectors.getActiveWorkImageIndex(state)
})

const mapDispatch = {
  closeWork: () => uiActions.selectWorkIndex(null),
  nextSelectedWorkImage: uiActions.nextSelectedWorkImage,
  prevSelectedWorkImage: uiActions.prevSelectedWorkImage,
  setActiveWorkImageIndex: uiActions.setActiveWorkImageIndex
}

export default connect(mapState, mapDispatch)(Work)
