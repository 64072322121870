import styled from 'styled-components'
import browserDetect from 'browser-detect'

const browser = browserDetect()

const StyledVideo = styled.video`
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  /* filter: blur(1px); */
  filter: opacity(0.5);
`

const Gif = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: url(${process.env.PUBLIC_URL + '/stoneGlow.gif'}) no-repeat center center fixed;
  background-size: cover;
`
const ColorLayer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  opacity: 0.75;
`

const Video = () => {
  if (browser.mobile || true) {
    return (
      <>
        <Gif />
        <ColorLayer />
      </>
    )
  }

  // return (
  //   <StyledVideo autoPlay playsInline controls="" muted loop>
  //     <source src={process.env.PUBLIC_URL + '/stoneGlow.webm'} type="video/webm" />
  //     <source src={process.env.PUBLIC_URL + '/stoneGlow.mp4'} type="video/mp4" />
  //   </StyledVideo>
  // )
}

export default Video
