import React from 'react'
import ReactDOM from 'react-dom'
import 'normalize.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import rootReducer from './store'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'

let commonMiddleware = [thunk]

const isDev = process.env.NODE_ENV === 'development'

if (isDev) {
  commonMiddleware.push(createLogger())
}

const store = createStore(rootReducer, applyMiddleware(...commonMiddleware))

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(isDev ? console.log : undefined)
