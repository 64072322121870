import React, { useState, useEffect, useRef } from 'react'
import { useTransition, animated } from '@react-spring/web'
import isFinite from 'lodash/isFinite'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import times from 'lodash/times'
import ceil from 'lodash/ceil'
import styled from 'styled-components'
import { device } from '../../helpers/deviceSize'

const GalleryWrapper = styled.div`
  opacity: 0.8;
  position: relative;
  min-height: 60vh;
  margin-bottom: 2.5rem;
  cursor: ${p => (p.onClick ? 'pointer' : 'default')};
  cursor: ${p => (p.onClick ? 'e-resize' : 'default')};

  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
`

const IndicatorWrapper = styled.div`
  position: absolute;
  top: calc(${p => p.top} + 1.5rem);
  left: 0;
  right: 0;
  padding: 0.75rem;
  display: flex;
  justify-content: center;
`

const Circle = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  background-color: ${p => (p.active ? 'rgba(0,0,0,0.8)' : 'rgba(255,255,255, 0.8)')};
  transform: scale(${p => (p.active ? '1.3' : '1')});
  transform-origin: center;
  border-radius: 50%;
  margin-right: 0.2rem;
  transition: all 0.3s ease-in-out;
`

const Indicators = ({ amount, active, top }) => {
  if (amount > 1) {
    return (
      <IndicatorWrapper top={top}>
        {times(amount, index => (
          <Circle key={index} active={active === index} />
        ))}
      </IndicatorWrapper>
    )
  }
  return null
}

const PictureButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 0;
  outline: 0;
  font-size: 0;
  color: transparent;
  background-color: transparent;
  @media ${device.mobileS} {
    display: none;
  }

  @media ${device.laptop} {
    display: block;
  }
`

const NextPictureButton = styled(PictureButton)`
  /* background-color: rgba(255, 0, 0, 0.3); */
  left: 50%;
  cursor: e-resize;
`

const PrevPictureButton = styled(PictureButton)`
  /* background-color: rgba(0, 0, 255, 0.3); */
  right: 50%;
  cursor: w-resize;
`

export default function FadeSlider({
  images,
  activeImageIndex,
  activeWorkIndex,
  prevImage,
  nextImage
}) {
  const [imagePath, setImagePath] = useState(null)

  const [touchStartX, setStartTouchX] = useState(undefined)
  const [touchEndX, setEndTouchX] = useState(undefined)
  const [touchStartY, setStartTouchY] = useState(undefined)
  const [touchEndY, setEndTouchY] = useState(undefined)

  const [inidicationTop, setInidicationTop] = useState(null)
  const galleryRef = useRef(null)

  useEffect(() => {
    if (touchStartX && touchEndX) {
      const xMovement = Math.abs(Math.abs(touchStartX) - Math.abs(touchEndX))
      const yMovement = Math.abs(Math.abs(touchStartY) - Math.abs(touchEndY))
      const isHorizontalMove = xMovement > yMovement

      if (isHorizontalMove) {
        if (touchStartX > touchEndX && touchStartX - touchEndX > 150) {
          nextImage()
        }

        if (touchStartX < touchEndX && touchStartX - touchEndX < 150) {
          prevImage()
        }
      }

      setStartTouchX(0)
      setEndTouchX(0)
    }
  }, [touchStartX, touchEndX])

  useEffect(() => {
    const path = `${activeImageIndex}.src`
    if (!isFinite(activeWorkIndex)) {
      setImagePath(null)
      return
    }

    setImagePath(path)
  }, [activeImageIndex, images, activeWorkIndex])

  useEffect(() => {
    const width = galleryRef?.current?.getBoundingClientRect()?.width
    if (width) {
      setInidicationTop(ceil(width))
    }
  }, [galleryRef])

  const transitions = useTransition(imagePath, {
    key: imagePath,
    from: { opacity: 0 },
    enter: { opacity: 0.6 },
    leave: { opacity: 0 },
    config: { duration: 250 },
    exitBeforeEnter: true
  })

  const handleTouchStart = event => {
    setStartTouchX(event.touches[0].screenX)
    setStartTouchY(event.touches[0].screenY)
  }

  const handleTouchEnd = event => {
    setEndTouchX(event.changedTouches[0].screenX)
    setEndTouchY(event.changedTouches[0].screenY)
  }

  const hasMoreThanOneImage = images?.length > 1

  if (images?.length > 0) {
    return (
      <GalleryWrapper ref={galleryRef} onClick={hasMoreThanOneImage ? nextImage : undefined}>
        {transitions((style, imagePath) =>
          isNil(imagePath) ? null : (
            <animated.img
              src={get(images, imagePath)}
              style={style}
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
            />
          )
        )}
        {inidicationTop ? (
          <Indicators top={inidicationTop} amount={images.length} active={activeImageIndex} />
        ) : null}
        {hasMoreThanOneImage & false ? (
          <>
            <NextPictureButton onClick={nextImage}>Next</NextPictureButton>
            <PrevPictureButton onClick={prevImage}>Prev</PrevPictureButton>
          </>
        ) : null}
      </GalleryWrapper>
    )
  }
  return null
}
