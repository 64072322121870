import React from 'react'
import styled, { keyframes } from 'styled-components'
import { connect } from 'react-redux'
import { uiSelectors } from '../store/ui'
import browserDetect from 'browser-detect'
import RainbowText from './StyledComponents/RainbowText'
import { device } from '../helpers/deviceSize'

const browser = browserDetect()
const isIphone = browser.mobile && browser.os.includes('OS X')

const ClipMask = styled.div`
  clip-path: circle(${p => (p.isOpen ? p.expandedSize : '1vh')} at center);
  transition: clip-path 3s ease-in-out;
`

const colors = ['#FFFFFF', '#D4492C', '#7EAED0', '#8D2662', '#8ECCA6']

const Wrapper = styled.div`
  /* filter: drop-shadow(0px 0px 15px rgba(255, 255, 255, 0.8)); */
  position: fixed;
  z-index: 0;
  &:after {
  }
`
const Shadower = styled.div`
  /* filter: drop-shadow(0px 0px 15px rgba(255, 255, 255, 0.8)); */
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
`
const spacerHeight = '30vh'

const TitleWrapper = styled.header`
  position: absolute;
  top: 30vh;
  left: 0;
  right: 0;

  bottom: calc(${isIphone ? '30vh' : '20vh'} - ${isIphone ? '5rem' : '0rem'});
  @media ${device.tablet} {
    bottom: 30vh;
  }
`

const TitleSpan = styled(RainbowText)`
  color: white;
  font-weight: 400;
  text-align: center;
  margin: 0;
  transition: color 2s ease-in-out;
  text-transform: uppercase;
  background: linear-gradient(to right, #fff, #333, #fff, #222, #fff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  background-size: 400% 100%;
  opacity: 0.8;
  font-size: 1.8rem;
  display: inline-block;
  animation-duration: 15s;
  position: absolute;
  left: 0;
  right: 0;
  /* width: 100%; */
`

const TitleTop = styled(TitleSpan)`
  /* padding-bottom: 50vh; */
  /* position: absolute;
  top: -20vh; */
  top: 0;
`

const TitleBottom = styled(TitleSpan)`
  /* position: relative;
  top: 5vh; */
  bottom: 0;
`

const FakeWhite = styled.div`
  position: fixed;
  background-color: white;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  opacity: ${p => (p.show ? 1 : 0)};
  transition: all 0.3s ease-in-out;
  display: ${p => (p.hide ? 'none' : 'block')};
`

const UnstylledButton = styled.button`
  position: absolute;
  outline: none;
  border: 0;
  background-color: transparent;
  top: calc(50% - 10vh);
  right: calc(50% - 10vh);
  z-index: 1;
  padding: 10vh;
  cursor: pointer;
`

const expandedSize = `150${window.innerHeight > window.innerWidth ? 'vh' : 'vw'}`

function Mask({ children, openIntroDot, introDotOpen }) {
  const [hideFakeWhite, setHideFakeWhite] = React.useState(false)

  React.useEffect(() => {
    if (introDotOpen) {
      setTimeout(() => setHideFakeWhite(true), 1000)
    }
  }, [introDotOpen])

  return (
    <>
      {!introDotOpen ? (
        <TitleWrapper>
          <h1>
            <TitleTop>
              What is behind the <span sr-only="dot" />
            </TitleTop>
            <TitleBottom>?</TitleBottom>
          </h1>
          <UnstylledButton onClick={openIntroDot} sr-only="open dot" />
        </TitleWrapper>
      ) : null}
      <Wrapper>
        <ClipMask expandedSize={expandedSize} isOpen={introDotOpen}>
          {children}
          <FakeWhite show={!introDotOpen} hide={hideFakeWhite} id="Fake" />
        </ClipMask>
      </Wrapper>
    </>
  )
}

const mapState = state => ({
  introDotOpen: uiSelectors.introDotOpen(state)
})

const mapDispatch = {
  openIntroDot: () => ({ type: 'EXPAND_INTRO_DOT' })
}

export default connect(mapState, mapDispatch)(Mask)
