import _fp from 'lodash/fp'

const initialState = {
  introImage: '/images/about/natali-blugerman.jpg',
  introText: [
    `Through my installations and performances, I create space to have strong experiences of aspects of the universe we are a part of. My aim is to expand our notion of ourselves in order to break the boundaries of what we think we are and open ourselves to new possibilities. My main tools for this are space, natural elements, materials and phenomena. The deconstruction of images in light, darkness and the mind has been an endless vessel of fascination for me since 2014.`
  ],
  sections: [
    {
      title: 'EXHIBITIONS/EVENTS',
      text: [
        `Mediamatic (Amsterdam, NL) 2020`,
        `Mediamatic- Odorama (Amsterdam, NL) 2018 & 2019`,
        `Mediamatic- Amsterdam Museum Nacht (Amsterdam, NL) 2018`,
        `Mediamatic- Museum night for the blind (Amsterdam, NL) 2018`,
        `Quartair - iii (Den Haag, NL) 2017`,
        `TodaysArt (Den Haag, NL) 2017`,
        `Hoogtij Festival (Den Haag, NL) 2017`,
        `iii - No Patent Pending (Den Haag, NL) 2017`,
        `KABK (Den Haag, NL) 2016`,
        `Zaal 3 (Den Haag, NL) 2016`,
        `Locatie Z (Den Haag, NL) 2016`,
        `Zaal 3 (Den Haag, NL) 2015`,
        `European Planetary Science Congress (Nice, FR) 2015`,
        `De Waag Society (Amsterdam, NL) 2014`,
        `Protokol (Belgrade, SB) 2014`,
        `La Bodega del Pintor (Buenos Aires, ARG) 2012`,
        `Salaborsa public library bar (Bologna, IT) 2011`,
        `Asociación Estímulo de Bellas artes (Buenos Aires, ARG) 2010`,
        `La Brisas Country Club (Buenos Aires, ARG) 2009`
      ]
    },
    {
      title: 'AWARDS AND GRANTS',
      text: [
        `Pro Research grant. Stroom (Den Haag, NL) 2021`,
        `Pro Invest grant. Stroom (Den Haag, NL) 2017`,
        `Documentation grant. Stroom (Den Haag, NL) 2017, 2020 & 2021`,
        `ArtScience Award. KABK (Den Haag, NL) 2016`,
        `Grant. KABK (Den Haag, NL) 2013`
      ]
    },
    {
      title: 'RESIDENCIES',
      text: [
        `Arakawa & Gins - Self residency- Mitaka, Yoro, Nagi- Japan- 2019`,
        `Quartair (Den Haag, NL) 2017`,
        `CLOUD/danslab (Den Haag, NL) 2017`
      ]
    },
    {
      title: 'STUDIES',
      text: [
        `INSIDE Masters Interior Architecture- KABK- 2018-20`,
        `Artscience bachellor degree. Royal Academy of Art (KABK) (Den Haag, NL) 2012-16`,
        `1st year Painting degree. Accademia di Belle Arti (Bologna, IT) 2010-11`,
        `Degree in Journalism.Taller Escuela Agencia (Buenos Aires, ARG) 2007-09`,
        `Painting and drawing, Roberto Bosco ́s Atelier (Buenos Aires, ARG) 2005-10`,
        `Industrial Design high school. Escuela Técnica ORT (Buenos Aires, ARG) 2002-06`
      ]
    }
  ],
  footer: {
    mail: 'n.blugerman@gmail.com',
    thanks: 'Special thanks for the support to:',
    thanksImages: ['/images/about/stroom_logo_nl.gif']
  }
}

export const aboutReducer = (state = initialState, action = {}) => {
  return state
}

const getAbout = _fp.get('about')

export const aboutSelectors = {
  getAbout
}

export const aboutActions = {}
