import _ from 'lodash'
import { connect } from 'react-redux'
import styled, { keyframes } from 'styled-components'
import { uiActions } from '../store/ui'

const goBackButtonAnimation = keyframes`
  0%,100% {
      transform: translateX(0rem);
  }

  50% {
    transform: translateX(-1rem);
  }
`

const BackButton = styled.button`
  outline: none;
  border: 0;
  background-color: transparent;
  margin: 0;
  padding: 1rem;
  padding-left: 0;
  cursor: pointer;
  animation: ${goBackButtonAnimation} ${p => (p.showAnimation ? ' 1.5s ease-in-out infinite;' : '')};

  &:hover svg {
    animation: ${goBackButtonAnimation} 1.5s ease-in-out infinite;
  }

  svg {
    width: 2.8rem;
    height: 2.8rem;
    color: white;
  }
`
const ChrevorLeft = () => (
  <svg viewBox="0 0 24 24">
    <path fill="currentColor" d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
  </svg>
)

function GoBackButton(props) {
  return (
    <BackButton
      onClick={props.closeWork}
      showAnimation={props.showBackButtonAnimation}
      sr-only="Go back"
      {...props}
    >
      <ChrevorLeft />
    </BackButton>
  )
}

const mapDispatch = {
  closeWork: () => uiActions.selectWorkIndex(null)
}

export default connect(null, mapDispatch)(GoBackButton)
