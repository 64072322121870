import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import times from 'lodash/times'
import map from 'lodash/map'
import round from 'lodash/round'
import { useSpring, animated } from 'react-spring'
import useWindowDimensions from '../helpers/useWindowDimensions'
import { connect } from 'react-redux'
import { worksSelectors } from '../store/works'
import { get } from 'lodash'
import { uiActions } from '../store/ui'
import browserDetect from 'browser-detect'
import { device } from '../helpers/deviceSize'

const browser = browserDetect()
const isMobile = browser.mobile
const isIphone = browser.mobile && browser.os.includes('OS X')

const calcGrid = (width, heigth, itemsAmount) => {
  const ratio = width / heigth
  let ncols_float = Math.sqrt(itemsAmount * ratio)
  let nrows_float = itemsAmount / ncols_float

  // Find best option filling the whole height
  let nrows1 = Math.ceil(nrows_float)
  let ncols1 = Math.ceil(itemsAmount / nrows1)
  while (nrows1 * ratio < ncols1) {
    nrows1++
    ncols1 = Math.ceil(itemsAmount / nrows1)
  }
  let cell_size1 = heigth / nrows1

  // Find best option filling the whole width
  let ncols2 = Math.ceil(ncols_float)
  let nrows2 = Math.ceil(itemsAmount / ncols2)
  while (ncols2 < nrows2 * ratio) {
    ncols2++
    nrows2 = Math.ceil(itemsAmount / ncols2)
  }
  let cell_size2 = width / ncols2

  // Find the best values
  let nrows, ncols, cell_size
  if (cell_size1 < cell_size2) {
    nrows = nrows2
    ncols = ncols2
    cell_size = cell_size2
  } else {
    nrows = nrows1
    ncols = ncols1
    cell_size = cell_size1
  }
  return { sizeLength: cell_size, rows: nrows, cols: ncols }
}

//

const BallsWrapper = styled.div`
  padding: 0;
  padding-top: 10vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  position: absolute;
  z-index: 1;
  overflow: hidden;
`

const BallRowWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-wrap: wrap;
`

const BallRow = styled.div`
  display: flex;
  justify-content: space-around;

  justify-content: center;
  flex: 1;
  &:not(:first-child) {
    margin-top: -3rem;
  }
  @media ${device.laptop} {
    justify-content: center;
    &:not(:first-child) {
      margin-top: 0;
    }
  }
`

const SafeArea = styled.div`
  height: ${p => p.height}px;
  width: ${p => p.width}px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  /* justify-content: center; */

  @media ${device.laptop} {
    justify-content: center;
  }
`

const ballColor = `rgba(255, 255, 255, 40%)`

const Ball = styled.div`
  display: inline-block;
  padding: ${p => p.radius}px;
  background-color: ${ballColor};

  @media ${device.mobileS} {
    transform: scale(0.8);
  }
  @media ${device.laptop} {
    transform: scale(1);
  }
  color: #333;

  border-radius: 50%;
  position: relative;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    background-color: #fff;
    background-color: rgb(222 255 0 / 50%);
    transform: scale(1.1);
    &::after {
      color: #deff00;
      font-weight: bold;
      top: calc(100% + 1rem);
      left: 50%;
      transform: rotate(360deg) translateX(-50%);
      background-color: red;
    }
  }
  &:after {
    content: '${p => p.title}';
    position: absolute;
    transition: all 0.3s ease-in-out;
    display: inline-block;
    text-align: center;
    line-height: 0;

    transform: rotate(300deg);
    top: ${p => p.radius * 1.7}px;
    left: ${p => p.radius * 1.7}px;
    width: 4rem;
    text-align: center;
  }
`

const About = styled.div`
  position: relative;
`

const plusIconSize = '2'

const AboutButtonWrapper = styled.div`
  position: fixed;
  right: 0rem;
  display: flex;
  cursor: pointer;
  @media ${device.mobileS} {
    margin-bottom: -1.5rem;
    margin-right: -1.5rem;
    bottom: ${isIphone ? 6 : 7}rem;
  }
  @media ${device.laptop} {
    margin-bottom: 0;
    margin-right: 0;
    bottom: 0rem;
  }

  ${About} {
    width: ${p => p.side}px;
    height: ${p => p.side}px;
  }
  & > ${About}:after {
    content: '+';
    position: absolute;
    display: block;
    font-size: ${plusIconSize}rem;
    top: calc(${p => p.side / 2}px - 1.5rem);
    left: calc(${p => p.side / 2}px - 1.37rem);

    line-height: 1.5rem;
    text-align: center;
    transform: rotate(0deg);
    transition: all 0.5s ease-in-out;
    font-weight: 300;
  }

  & > ${About}:before {
    content: '';
    background-color: ${ballColor};
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: 0 1.5rem 1.5rem 0;
    transition: all 0.5s ease-in-out;
    transform: rotate(0deg);
  }

  &:hover > ${About}:after {
    color: #deff00;
    transform: rotate(0deg);
  }

  &:hover > ${About}:before {
    color: #deff00;
    transform: rotate(225deg);
  }
`

const Balls = ({ show, works, selectWorkIndex, openAbout }) => {
  const [sideLength, setSideLength] = useState(0)
  const [grid, setGrid] = useState([])

  const { height, width } = useWindowDimensions()
  const aboutSection = 1
  const ballsAmount = works.length + aboutSection

  useEffect(() => {
    const bufferHeight = height * 0.15
    const bufferWidth = 0
    const { sizeLength, rows, cols } = calcGrid(
      width - bufferWidth,
      height - bufferHeight,
      ballsAmount
    )

    let grid = []
    let counter = 0
    times(rows, row => {
      grid.push([])
      times(cols, col => {
        if (counter < ballsAmount) {
          grid[row].push(counter)
        }

        counter++
      })
    })

    setGrid(grid)

    setSideLength(round(sizeLength))
  }, [height, width, works])

  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 }
  }))

  const trans1 = (x, y) => `translate3d(${Math.floor(x / 100)}px,${Math.floor(y / 100)}px,0)`

  const ballsSpring = {
    transform: props.xy.to(trans1),
    height: '100%'
  }

  if (!show) {
    return null
  }

  return (
    <BallsWrapper>
      <animated.div style={ballsSpring}>
        <BallRowWrapper>
          {map(grid, (cols, index) => {
            return (
              <BallRow key={index}>
                {map(cols, workIndex => {
                  const isAbout = workIndex + 1 === ballsAmount
                  if (!isAbout) {
                    const work = get(works, workIndex)
                    return (
                      <SafeArea key={workIndex} width={sideLength} height={sideLength}>
                        <Ball
                          radius={sideLength / 5 + 15}
                          // randomX={random(10, (sideLength - 60) / 2)}
                          // randomY={random(0, (sideLength - 60) / 2)}
                          randomX={5}
                          randomY={sideLength / 6}
                          title={work?.shortName}
                          onClick={() => selectWorkIndex(workIndex)}
                        />
                      </SafeArea>
                    )
                  }
                  return (
                    <AboutButtonWrapper
                      key={workIndex}
                      side={sideLength / (isMobile ? 1.5 : 3)}
                      onClick={openAbout}
                    >
                      <About title="About me" />
                    </AboutButtonWrapper>
                  )
                })}
              </BallRow>
            )
          })}
        </BallRowWrapper>
      </animated.div>
    </BallsWrapper>
  )
}

const mapDispatch = {
  selectWorkIndex: uiActions.selectWorkIndex,
  openAbout: uiActions.openAbout
}

const mapState = state => ({
  works: worksSelectors.getWorks(state)
})

export default connect(mapState, mapDispatch)(Balls)
